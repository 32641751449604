module.exports = [{
      plugin: require('../plugins/gatsby-plugin-typestyle/gatsby-browser.js'),
      options: {"plugins":[],"styleTargetId":"pyonpyon-style"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":true,"backgroundColor":"none","loading":"auto","quality":90,"withWebp":{"quality":93},"withAvif":false,"showCaptions":false,"markdownCaptions":false,"decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pyon Pyon Today","short_name":"Pyon Pyon Today","start_url":"/","background_color":"#ffffff","theme_color":"#3b6ece","display":"standalone","icon":"resource/IMG_2628_02.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"none","lang":"zh-cmn-Hans","theme_color_in_head":false,"legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/about","/p/*","/page/*"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
